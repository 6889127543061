import React, { useState } from "react";
import Images from "assets/images";
import Chip from "components/chip/Chip";
import Card from "components/visitCommon/Card";
import Alert from "components/alerts/Alert";
import useModal from "hooks/useModal";
import {
  medicationDosageForms,
  medicationFrequencies,
  medicationPerValues,
} from "helpers/statics";

function DoseTextField({ disabled, value, setSelected, index }) {
  const [dose, setDose] = useState(value);
  return (
    <input
      type="text"
      style={{
        background: "transparent",
      }}
      value={dose}
      disabled={disabled}
      onChange={(event) => setDose(event.target.value)}
      onBlur={() => {
        setSelected((prev) => {
          return prev.map((med, idx) => {
            if (index === idx) {
              return {
                ...med,
                dose,
              };
            }
            return med;
          });
        });
      }}
    />
  );
}

function SearchInputWithChipCard({
  onClickInput,
  inputLabel,
  selected,
  setSelected,
}) {
  const { setShowModal } = useModal();
  return (
    <Card>
      <div
        onClick={onClickInput}
        className="flex items-center justify-between border border-borderColor rounded-1.6 p-6 cursor-text"
      >
        <p className="text-2xl font-bold opacity-40">{inputLabel}</p>
        <img
          className="w-8 h-8 object-contain"
          src={Images.searchIcon}
          alt={"search"}
        />
      </div>
      {selected?.length ? (
        <div className="mt-5 pt-8 px-12 border-t border-borderColor -mr-10 -ml-10">
          <div className="flex flex-wrap overflow-x-auto">
            {inputLabel === "Search Medication"
              ? selected.map((drug, index) => (
                  <div key={drug?.name} className="w-full">
                    <div className="mb-2 flex justify-between items-center">
                      <p className="font-semibold text-2xl text-indigo">
                        {drug?.name}
                      </p>
                      <div className="flex space-x-2 items-center">
                        <img
                          onClick={() => {
                            setShowModal({
                              state: true,
                              children: (_, onClose) => (
                                <Alert
                                  heading="Notice:"
                                  message="Don't worry! You can re-add it in your health profile if you need to in the future"
                                  primaryButtonText="Confirm"
                                  secondaryButtonText="Cancel"
                                  onPrimaryButtonClick={() => {
                                    onClose();
                                    setSelected((prev) => {
                                      return prev?.filter(
                                        (med, idx) => index !== idx && med
                                      );
                                    });
                                  }}
                                  onSecondaryButtonClick={onClose}
                                />
                              ),
                            });
                          }}
                          src={Images.deleteIcon}
                          className="mx-auto w-10 h-10 cursor-pointer"
                          alt="delete"
                        />
                        <p className="hidden font-medium text-2xl text-indigo md:block">
                          Inactivate
                        </p>
                      </div>
                    </div>

                    <div className="mb-4 pb-4 border-b border-grey-600 flex flex-col items-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                      <div className="w-full flex items-center space-x-4">
                        <div className="flex-1 text-2xl text-indigo">
                          <label className="text-2xl font-bold text-opacity-50 text-indigo">
                            Dosage
                          </label>
                          <div className="custom_input">
                            <DoseTextField
                              disabled={drug?.dose?.length > 0}
                              value={drug?.dose}
                              setSelected={setSelected}
                              index={index}
                            />
                          </div>
                        </div>

                        <div className="flex-1 text-2xl text-indigo">
                          <label className="text-2xl font-bold text-opacity-50 text-indigo">
                            Form
                          </label>
                          <div className="relative flex flex-col custom_input">
                            <select
                              className="relative cursor-pointer !pr-12"
                              disabled={drug?.form?.length > 0}
                              defaultValue={drug?.form}
                              onChange={(event) => {
                                setSelected((prev) => {
                                  return prev.map((med, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...med,
                                        form: event.target.value,
                                      };
                                    }
                                    return med;
                                  });
                                });
                              }}
                            >
                              <option value="">Select a value</option>
                              {medicationDosageForms?.map((form) => (
                                <option key={form} value={form}>
                                  {form}
                                </option>
                              ))}
                            </select>
                            <img
                              src={Images.dropdown}
                              alt="chev_down"
                              className="absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex items-center space-x-4">
                        <div className="flex-1 text-2xl text-indigo">
                          <label className="text-2xl font-bold text-opacity-50 text-indigo">
                            Frequency
                          </label>
                          <div className="relative flex flex-col custom_input">
                            <select
                              className="relative cursor-pointer !pr-12"
                              disabled={drug?.frequency?.length > 0}
                              defaultValue={drug?.frequency}
                              onChange={(event) => {
                                setSelected((prev) => {
                                  return prev.map((med, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...med,
                                        frequency: event.target.value,
                                      };
                                    }
                                    return med;
                                  });
                                });
                              }}
                            >
                              <option value="">Select a value</option>
                              {medicationFrequencies?.map((el) => (
                                <option key={el} value={el}>
                                  {el}
                                </option>
                              ))}
                            </select>
                            <img
                              src={Images.dropdown}
                              alt="chev_down"
                              className="absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3"
                            />
                          </div>
                        </div>
                        <div className="flex-1 text-2xl text-indigo">
                          <label className="text-2xl font-bold text-opacity-50 text-indigo">
                            Per
                          </label>
                          <div className="relative flex flex-col custom_input">
                            <select
                              className="relative cursor-pointer !pr-12"
                              disabled={drug?.per?.length > 0}
                              defaultValue={drug?.per}
                              onChange={(event) => {
                                setSelected((prev) => {
                                  return prev.map((med, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...med,
                                        per: event.target.value,
                                      };
                                    }
                                    return med;
                                  });
                                });
                              }}
                            >
                              <option value="">Select a value</option>
                              {medicationPerValues?.map((per) => (
                                <option key={per} value={per}>
                                  {per}
                                </option>
                              ))}
                            </select>
                            <img
                              src={Images.dropdown}
                              alt="chev_down"
                              className="absolute right-2 transition duration-500 ease-in-out w-10 h-10 transform rotate-0 bottom-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : inputLabel === "Search Allergy"
              ? selected.map((el, index) => (
                  <Chip
                    key={index}
                    text={
                      inputLabel === "Search Medication" ||
                      inputLabel === "Search Allergy"
                        ? el?.name
                        : el
                    }
                    className="mb-8"
                    onClear={() => {
                      setSelected((prev) =>
                        prev?.filter((elem) => elem !== el)
                      );
                    }}
                    hideDelete={true}
                  />
                ))
              : selected.map((el, index) => (
                  <Chip
                    key={index}
                    text={
                      inputLabel === "Search Medication" ||
                      inputLabel === "Search Allergy"
                        ? el?.name
                        : el
                    }
                    className="mb-8"
                    onClear={() => {
                      setSelected((prev) =>
                        prev?.filter((elem) => elem !== el)
                      );
                    }}
                  />
                ))}
          </div>
        </div>
      ) : null}
    </Card>
  );
}

export default SearchInputWithChipCard;
